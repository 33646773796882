<script>
// import {Vue3Marquee} from "vue3-marquee"
import LobbyComponent from "@/components/lobby/lobby-component.vue"
import BetHistoryComponent from "@/components/room/bet-history-component.vue"
// import SelectRoomTypeComponent from "@/components/select-room-type-component.vue"

/**
 * Login component
 */
export default {
  components: {
    // Vue3Marquee,
    LobbyComponent,
    BetHistoryComponent,
    // SelectRoomTypeComponent
  },
  data() {
    return {
      currentLobbyHistoryType: 0,
      currentLobbyColType: 2,
      companyKey: '',
      company: undefined,
      alert: {
        type: 1,
        msg: '',
        visible: false
      },
      sound: {
        bg: true,
        effect: true
      },
      minAmount: 0,
      maxAmount: 0,
      windowResizeRatio: 0.000485,
      windowResizeSt: 1921,
      lobbyName:''
    }
  },
  computed: {

  },

  methods: {
    async myInfo () {
      try {
        const result = await this.$API.game.myInfo('')
        if (result.code !== 'OK') {
          throw result
        }

        this.$store.commit('auth/SET_CURRENT_USER', result.user)

        return true
      } catch(e) {
        this.$log.error(e)
        return false
      }
    },
    openBets () {
      if (!this.company) return
      this.$API.util.playSoundClick()
      const obj = document.getElementById('bet-history')
      if (obj) {
        obj.style.display = 'block'
        this.$refs['bet-history'].open()
      }
    },
    closeBets () {
      const obj = document.getElementById('bet-history')
      if (obj) {
        obj.style.display = 'none'
      }
    },
    setCompany(company) {
      this.company = company
    },
    exitGame () {
      this.$API.util.playSoundClick()
      window.close()
    },
    changeGrid (idx) {
      this.currentLobbyColType = idx
      this.$refs['game_lobby'].updateHistory()
    },
    changeHistoryType (idx) {
      this.currentLobbyHistoryType = idx
      this.$refs['game_lobby'].updateHistory()
    },
    reload () {
      this.$API.util.playSoundClick()
      document.location.reload()
    },

    setTableMode () {
      // 팝업 표시
      this.$API.util.showPopup('set_view');
      this.$refs.config_room_type.init()

      this.$API.util.openConfig()
    },

    adjustZoom () {
      var minWidth = 1800;
      var body = document.getElementsByTagName('body')[0];
      var chkMulti = 0;// document.getElementsByClassName("multi-wrapper").length;

      if (window.innerWidth < minWidth) {
        if( window.innerWidth < 768 && chkMulti < 1)  {
          if(window.innerWidth < 320 ) {
            body.style.zoom = 0.6;
          } else if(window.innerWidth < 400 ) {
            body.style.zoom = 0.7;
          } else if(window.innerWidth < 480 ) {
            body.style.zoom = 0.8;
          } else if(window.innerWidth < 768) {
            body.style.zoom = 0.9;
          } else {
            body.style.zoom = 1;
          }
        } else {
          //console.log((window.innerWidth / minWidth));
          body.style.zoom = (window.innerWidth / minWidth);
        }
      } else {
        body.style.zoom = 1;
      }
    },
    showAlert: function (type, msg) {
      this.alert.type = type;
      this.alert.msg = msg;
      this.alert.visible = true;
      setTimeout(this.hideAlert, 5000);
    },
    hideAlert: function () {
      this.$API.util.playSoundClick()
      this.alert.visible = false;
    },
    playBG () {
      this.$API.util.playBG()
      this.hideAlert()
    },
    openSoundConfig: function() {
      // 음악 체크박스 설정
      const chkSndConfigMusic = document.getElementById('chkSndConfigMusic');
      if (chkSndConfigMusic) {
        chkSndConfigMusic.checked = this.$root.enableMusic;
      }

      // 사운드 체크박스 설정
      const chkSndConfigSound = document.getElementById('chkSndConfigSound');
      if (chkSndConfigSound) {
        chkSndConfigSound.checked = this.$root.enableSound;
      }
      this.$API.util.showPopup('sound_config');

      this.$API.util.openConfig()
    },
    closeSoundConfig () {
      this.$API.util.playSoundClick()
      this.$API.util.closePopup('sound_config')
    },
    changeSoundConfig () {
      localStorage.setItem('soundBG', this.sound.bg)
      localStorage.setItem('soundEffect', this.sound.effect)
      this.$API.util.playSoundClick()
      this.$API.util.closePopup('sound_config')

      if (this.sound.bg === true) {
        this.$API.util.playBG()
      }
    },
    setMinMax (min, max) {
      this.minAmount = min
      this.maxAmount = max
    },
    resizeWindow: function (selector, threshold, ratio) {
      const windowWidth = window.innerWidth;
      const element = document.querySelector(selector);

      element.style.zoom = (windowWidth * ratio).toString();

      /*
      if (windowWidth < threshold) {
        element.style.zoom = (windowWidth * ratio).toString();
      } else {
        element.style.zoom = '1';
      }
       */
    },
  },
  mounted() {
    const self = this
    // this.adjustZoom()
    // window.addEventListener('resize', this.adjustZoom);
    // window.addEventListener('DOMContentLoaded', this.adjustZoom);

    const idx = localStorage.getItem('currentLobbyColType')
    if (idx) {
      this.currentLobbyColType = Math.floor(idx)
    } else {
      this.currentLobbyColType = 2
    }

    const idx2 = localStorage.getItem('currentLobbyHistoryType')
    if (idx2) {
      this.currentLobbyHistoryType = Math.floor(idx2)
    } else {
      this.currentLobbyHistoryType = 2
    }

    // const self = this
    const companyKey = this.$route.params.companyKey
    if (!companyKey) {
      alert('회사 코드를 확인하세요.')
      return window.close()
    }
    this.companyKey = companyKey

    this.myInfo().then()

    setTimeout(() => {
      self.$refs.game_lobby.open()
    }, 500)

    const bg = localStorage.getItem('soundBG')
    if (bg === 'true') {
      this.sound.bg = true
    } else {
      if (!bg) {
        this.sound.bg = true
      } else {
        this.sound.bg = false
      }
    }

    this.sound.bg = false

    const effect = localStorage.getItem('soundEffect')
    if (effect === 'true') {
      this.sound.effect = true
    } else {
      if (!effect) {
        this.sound.effect = true
      } else {
        this.sound.effect = false
      }
    }

    this.sound.effect = false

    /*
    if (this.sound.bg === true) {
      this.showAlert(1, '배경 음악을 재생하시겠습니까?')
    }
     */

    const w = document.documentElement.clientWidth;
    if (w > 800) {
    // if (this.$API.util.isMobile()) {
      const self = this

      this.resizeWindow("section.main_game", this.windowResizeSt, this.windowResizeRatio);
      window.addEventListener('resize', function () {
        self.resizeWindow("section.main_game", self.windowResizeSt, self.windowResizeRatio);
      });
      window.addEventListener('DOMContentLoaded', function () {
        self.resizeWindow("section.main_game", self.windowResizeSt, self.windowResizeRatio);
      });
    }
  },
  watch: {
    currentLobbyColType (val) {
      localStorage.setItem('currentLobbyColType', val.toString())
    },
    currentLobbyHistoryType (val) {
      localStorage.setItem('currentLobbyHistoryType', val.toString())
    },
  },
}
</script>

<style scoped>
.btn-tc-primary {
  height:23px;
  line-height:23px;
  margin:1px 0;
  text-align:center;
  border-radius:3px;
  color: white;
  text-decoration: none;
  font-weight: normal;
  background:linear-gradient(180deg,#111 0%, #333 100%);
}

.selected {
  background:linear-gradient(180deg,#526130 0%, #333c23 100%);
}

.desktop-layout {
  padding-left: 30px;
}
</style>

<template>
  <section class="main_game" style="zoom: 1;">
    <div class="container_lobby" id="containerLobby">
      <div id="roadMapWrapLobby" class="roadmap_wrap_lobby" style="padding: 30px;">
        <div class="mask"></div>
        <header style="width: 100%;">
          <div class="info" v-if="$store.getters['auth/user']">
            <div class="user_info">
              <div class="user">ID : <span>{{ $store.getters['auth/user'].nickname }}</span></div>
              <div class="money">잔액 : <span>{{ $store.getters['auth/user'].cash.toThousands() }}</span></div>
            </div>
            <div class="notice">
              <div class="content" style="display: flex; padding-left: 0;">
                <img src="/imgs/ic_notice.png" alt="">
                <p>호텔 카지노 게임에 오신것을 환영합니다.</p>
              </div>
            </div>
            <ul class="menu">
              <li onclick="location.reload()">새로고침</li>
              <li class="bet showMask" @click="openBets">베팅내역</li>
              <li class="show_notice showMask">공지안내</li>
            </ul>
          </div>
        </header>

        <ul class="bet_grade" id="bet_grade" style="width: 100%;">
          <!-- 크리드는 무조건 4개만 사용 -->

          <li data-lvl="1" class="on">
            <div>Minimum<span>{{ minAmount.toThousands() }}</span></div>
            <div>Maximum<span>{{ maxAmount.toThousands() }}</span></div>
          </li>

        </ul>

        <div class="" id="roadmap_list">

          <div class="blocks grid-3" :class="{'mobile-layout': $API.util.isMobile() === true, 'desktop-layout': $API.util.isMobile() === false}" style="text-align: center;">
            <lobby-component ref="game_lobby" @setMinMax="setMinMax" :companyKey="companyKey" :lobbyColType="currentLobbyColType" :lobbyHistoryType="currentLobbyHistoryType" @setCompany="setCompany" />
          </div>

        </div>
      </div>
    </div>
  </section>

  <bet-history-component @closeBets="closeBets" :company="company" id="bet-history" ref="bet-history" />

</template>
